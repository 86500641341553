import { storeToRefs } from 'pinia';
import { useSumStore } from './sum.store';

import type { TPossibleNull } from '~/types/Shared.types';
import { EPromoTypes, type IBloggerOffer, type TPromo } from '~/features/payment/types/promo/client.types';
import type { IPaymentApplyInstPromoBody } from '~/repository/modules/payment/PaymentQueries.types';
import { useUserStore } from '~/store/user/user.store';
import { useAlertStore } from '~/store/alert/alert.store';
import { PROMO_INPUT_DEBOUNCE } from '~/features/payment/constants/times.constants';
import { useResultsStore } from '~/features/payment/store/results.store';
import { EResultTypes, EResultSuccessTypes } from '~/features/payment/types/result/client.types';
import { PaymentEvents } from '~/repository/amplitude/events/payment';

interface IBloggerRewardsItem {
  bonus: IBloggerOffer;
  isActive: boolean;
  isDisabled: boolean;
}

export const usePromoStore = defineStore('payment/promo', () => {
  const {
    $api: { payment: PaymentService },
  } = useNuxtApp();

  const { t } = useI18n();

  const resultStore = useResultsStore();
  const { result } = storeToRefs(resultStore);

  /* -- Stores -- */
  /* Стор суммы */
  const sumStore = useSumStore();
  const { sumWithRate } = storeToRefs(sumStore);

  /* -- Const -- */
  const promoInfo = reactive({
    error: '',
    input: '',
    isSucceed: false,
  });
  /* Текущий промокод */
  const currentPromo = ref<TPossibleNull<TPromo>>(null);
  const debounce = ref<ReturnType<typeof setTimeout>>();

  /* -- Getters -- */
  /* Обычный промокод ( с процентом ) */
  const commonPromo = computed(() => {
    if (currentPromo.value?.type === EPromoTypes.COMMON) return currentPromo.value.percent;
    return 0;
  });
  /* Блоггерский промокод ( с бонусами ) */
  const bloggerPromo = computed(() => {
    if (currentPromo.value?.type === EPromoTypes.BLOGGER) return currentPromo.value.offers;
    return null;
  });
  /* Мгновенный промокод */
  const instantPromo = computed(() => {
    if (currentPromo.value?.type === EPromoTypes.INSTANT) return currentPromo.value.sum;
    return 0;
  });

  /* Активный блоггерский бонус ( на основе суммы ) */
  const activeBloggerBonus = computed(() => {
    const result = bloggerPromo.value?.findLast((bonus) => bonus.from <= sumWithRate.value);
    return result ?? null;
  });
  /* Блоггерские бонусы для вида экрана оплаты скинами */
  const skinsBloggerBonuses = computed(() => {
    return bloggerPromo.value?.map((bonus, index) => ({
      bonus,
      level: index + 1,
    }));
  });
  /* Получаем блоггерские бонусы для карточек-наград */
  const bloggerRewards = computed(() => {
    if (!bloggerPromo.value) return [];

    const result: IBloggerRewardsItem[] = [];
    const bonusesLength = bloggerPromo.value.length;

    for (let index = 0; index < bonusesLength; index++) {
      const bonus = bloggerPromo.value[index];
      const sumLargerThanBonus = bonus.from <= sumWithRate.value;
      if (sumLargerThanBonus && index < bonusesLength - 2) continue;

      result.push({
        bonus,
        isActive: index === bonusesLength - 1 && sumLargerThanBonus,
        isDisabled: index === bonusesLength - 2 && sumLargerThanBonus,
      });

      if (result.length === 2) break;
    }

    return result;
  });

  const isLoading = ref(false);

  const img = computed(() => (currentPromo.value?.type !== EPromoTypes.INSTANT ? currentPromo.value?.img : undefined));

  /* -- Methods -- */
  /* Устанавливаем значение для стейта промокода */
  const setPromoInfo = (promo: TPossibleNull<TPromo>, isSucceed: boolean, error: string) => {
    const errorCodes = {
      'promo_code_not_found_or_expired': 'notFoundOrExpired',
      'promo_code_already_used': 'alreadyUsed',
      'promo_code_not_found': 'notFound',
      'Превышен лимит попыток': 'attemptsLimit',
    };
    const localizedError = errorCodes[error as keyof typeof errorCodes];

    currentPromo.value = promo;
    promoInfo.isSucceed = isSucceed;
    promoInfo.error = localizedError ? t(`PaymentPage.promoErrors.${localizedError}`) : '';
  };

  /* Получаем промокод */
  const getPromo = async (promo: string) => {
    const encodedPromo = encodeURIComponent(promo);

    if (!encodedPromo) {
      setPromoInfo(null, false, '');
      return;
    }

    try {
      const promoResponse = await PaymentService.getPromo(encodedPromo);

      if ('message' in promoResponse) {
        setPromoInfo(null, false, promoResponse.message);
        return;
      }

      setPromoInfo(promoResponse, true, '');

      PaymentEvents.promoEntered({
        'Promo Name': promoInfo.input,
        'Promo Type': promoResponse.type,
      });
    } catch {
      setPromoInfo(null, false, t('lostLocales.payment.validation.undefinedCode'));
    }
  };

  const handlePromoInput = async (value: string) => {
    isLoading.value = true;
    if (debounce.value) {
      clearTimeout(debounce.value);
    }

    if (!value) {
      await getPromo(value);
      isLoading.value = false;
      return;
    }

    debounce.value = setTimeout(async () => {
      await getPromo(value);
      isLoading.value = false;
    }, PROMO_INPUT_DEBOUNCE);
  };

  const clearPromo = () => {
    currentPromo.value = null;
    promoInfo.input = '';
    promoInfo.error = '';
  };
  const applyInstantPromo = async () => {
    isLoading.value = true;
    if (currentPromo.value?.type !== EPromoTypes.INSTANT) {
      isLoading.value = false;
      return;
    }
    const payload: IPaymentApplyInstPromoBody = {
      userId: useUserStore().userId ?? 0,
      utmContent: promoInfo.input,
    };

    try {
      const response = await PaymentService.applyInstantPromo(payload);
      const { success, message } = response;

      if (!success) {
        useAlertStore().showError({
          message,
          title: t('lostLocales.payment.promoError'),
        });
      }

      result.value = {
        status: EResultTypes.SUCCESS,
        sum: instantPromo.value,
        type: EResultSuccessTypes.COMMON,
      };

      navigateTo(useLocaleRoute()(ROUTING.PAYMENT.RESULT));
      isLoading.value = false;
      currentPromo.value = null;
      promoInfo.input = '';
    } catch {}
  };

  watch(instantPromo, applyInstantPromo);

  return {
    activeBloggerBonus,
    bloggerPromo,
    bloggerRewards,
    commonPromo,
    currentPromo,
    getPromo,
    instantPromo,
    promoInfo,
    skinsBloggerBonuses,
    img,
    handlePromoInput,
    clearPromo,
    isLoading,
  };
});
