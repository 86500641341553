import { storeToRefs } from 'pinia';
import type { IAdditionalInfo, IPaymentStartPostBody } from '~/repository/modules/payment/PaymentQueries.types';
import { useCountryStore } from '~/features/payment/store/country.store';
import { useSumStore } from '~/features/payment/store/sum.store';
import { useMethodsStore } from '~/features/payment/store/methods.store';
import { ECountryMethodType } from '~/features/payment/types/country/client.types';
import { usePromoStore } from '~/features/payment/store/promo.store';
import { useAdditionalFieldsStore } from '~/features/payment/store/additionalFields.store';
import type { TPossibleNull } from '~/types/Shared.types';
import { ErrorCodes } from '~/api/global/errors/codes/codes';
import { useAlertStore } from '~/store/alert/alert.store';
import { useOffersStore } from '~/features/secret-shop/store/offers.store';

export const useTransactionStore = defineStore('payment/transaction', () => {
  const {
    $api: { payment: PaymentService },
  } = useNuxtApp();

  const redirectUrl = ref<TPossibleNull<string>>();
  const orderId = ref<TPossibleNull<string>>(null);
  const countryStore = useCountryStore();
  const { country } = storeToRefs(countryStore);

  const sumStore = useSumStore();
  const { sumWithRate } = storeToRefs(sumStore);

  const methodsStore = useMethodsStore();
  const { selectedMethod } = storeToRefs(methodsStore);

  const promocodeStore = usePromoStore();

  const additionalFieldsStore = useAdditionalFieldsStore();
  const { fields } = storeToRefs(additionalFieldsStore);

  const offersStore = useOffersStore();
  const { selectedOffer } = storeToRefs(offersStore);

  const alertStore = useAlertStore();

  const isLoading = ref(false);

  const promoCode = computed(() => {
    const promoInfo = promocodeStore.promoInfo;

    if (!promoInfo || !promoInfo.input) {
      return null;
    }

    return promoInfo.input;
  });

  const additionalInfo = computed<Partial<IAdditionalInfo> | null>(() => {
    if (Object.keys(fields.value).length === 0) {
      return null;
    }

    // Доказываю тайпскрипту, что я хитрее
    const result: Record<string, unknown> = {};
    for (const field of Object.entries(fields.value)) {
      const fieldName = field[0];
      const fieldValue = field[1].value;
      result[fieldName] = fieldValue;
    }

    return result;
  });

  const payload = computed<IPaymentStartPostBody>(() => {
    const payload: IPaymentStartPostBody = {
      countryIso: country.value?.country.iso,
      payMethod: selectedMethod.value?.payMethod ?? '',
      paymentDirectionId: selectedMethod.value?.id ?? 0,
      paymentType: selectedMethod.value?.type === ECountryMethodType.COMMON ? '1' : '2',
      sum: Number(GlobalUtils.Prices.toFixedDefault(sumWithRate.value)),
    };

    if (promoCode.value) {
      payload.promo = promoCode.value;
    }

    if (additionalInfo.value) {
      payload.additionalInfo = additionalInfo.value;
    }

    if (selectedOffer.value) {
      payload.offerId = `${selectedOffer.value.id}`;
    }

    return payload;
  });
  const startTransaction = async () => {
    try {
      isLoading.value = true;
      const result = await PaymentService.startPayment(payload.value);
      redirectUrl.value = result.url;
      orderId.value = result.orderId;
      localStorage.setItem('lastOrderId', orderId.value);
      if (selectedMethod.value) {
        localStorage.setItem('lastMethodId', `${selectedMethod.value.id}`);
      }
    } catch (e) {
      alertStore.showError({
        message: (e as { msg: string | undefined }).msg || '',
        title: ErrorCodes.UNPREDICTED_EXCEPTION,
      });
    } finally {
      isLoading.value = false;
    }
  };

  return {
    isLoading,
    orderId,
    redirectUrl,
    startTransaction,
  };
});
